import React, { Component } from 'react'
import emailjs from '@emailjs/browser';
import SectionTitle from '../../components/SectionTitle'
import vec1 from '../../images/contact/1.png'
import vec2 from '../../images/contact/2.png'

class RSVP extends Component {


    state = {
        name: '',
        email: '',
        guest: '',
        error: {}
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = async (e) => {

        e.preventDefault();

        const delay = ms => new Promise(
            resolve => setTimeout(resolve, ms)
          );

        const { name, email, guest, error } = this.state;

        if (name === '') {
            error.name = "Por favor ingresa tu nombre";
        }
        if (email === '') {
            error.email = "Por favor ingresa tu email";
        }
        if (guest === '') {
            error.guest = "Por favor selecciona el número de asistentes";
        }

        if (error) {
            this.setState({
                error
            })
        }

        if (error.name === '' && error.email === '' && error.email === '' && error.guest === '') {

            emailjs.sendForm('asis-lucia-juan', 'confirmacion-lucia-juan', e.target, 'LvCIjJlhw8DvfGOvZ')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
            error.submit = "Confirmación enviada correctamente";

            await delay(3000);

            this.setState({
                name: '',
                email: '',
                address: '',
                meal: '',
                guest: '',
                error: {}
            })
        }

    }

    render(){

        const { name,
            email,
            guest,
            error } = this.state;

        return(
            <section className={`wpo-contact-section ${this.props.pt}`} id="RSVP">
                <div className="container">
                    <div className="wpo-contact-section-wrapper">
                        <div className="wpo-contact-form-area">
                            <div class="form-title">
                                <SectionTitle MainTitle={'Confirma tu asistencia'}/>
                                <p class="form-control-date text-center" id="form-subtitle">Ha finalizado el plazo para confirmar tu asistencia.</p>
                            </div>
                            <form onSubmit={this.subimtHandler} className="form">
                                <div className="row">
                                    <div>
                                        <div className="form-field">
                                            <input value={name} onChange={this.changeHandler} className="form-control" type="text" name="name" placeholder="Nombre completo" disabled/>
                                            <p>{error.name ? error.name : ''}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-field">
                                            <input onChange={this.changeHandler} value={email} type="email" className="form-control" name="email" placeholder="Email" disabled/>
                                            <p>{error.email ? error.email : ''}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <select name="guest" className="form-control" value={guest} onChange={this.changeHandler} disabled>
                                            <option>Número de asistentes</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <p>{error.guest ? error.guest : ''}</p>
                                    </div>
                                    <div className="submit-area">
                                        <div className="form-submit">
                                            <button type="submit" name="submit" className="theme-btn-s3" disabled>Confirmar</button>
                                            <p>{error.submit ? error.submit : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="border-style"></div>
                        </div>
                        <div className="vector-1">
                            <img src={vec1} alt=""/>
                        </div>
                        <div className="vector-2">
                            <img src={vec2} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}
export default  RSVP;