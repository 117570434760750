import React from 'react'
import SectionTitle from '../SectionTitle'
import LocationMap from '../Modal'


const Events = [
    {
        title:'Civil',
        li1:'Viernes 8 de Diciembre, 10:00 horas',
        li2:'Centro Cultural Democrático',
        li3:'Manuel Freire y Pablo Zufriateguis',
        li4: 'Treinta y Tres',
        place: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d479.1934043491207!2d-54.38788216743412!3d-33.23251796445339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x950be900e2d1354f%3A0xcdc8f7a438d151eb!2sPablo%20Zufriategui%20%26%20Manuel%20Freire%2C%2033000%20Treinta%20y%20Tres%2C%20Departamento%20de%20Treinta%20y%20Tres!5e0!3m2!1ses-419!2suy!4v1697390702386!5m2!1ses-419!2suy'
    },
    {
        title:'Iglesia',
        li1:'Sábado 9 de Diciembre, 20:30 horas',
        li2:'Parroquia Virgen de los Treinta y Tres',
        li3:'Juan Ortiz y Aparicio Saravia',
        li4: 'Treinta y Tres',
        place: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3337.427892237049!2d-54.380269899999995!3d-33.2290918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x950be9032787a1df%3A0xf56be0afdf4ac8fb!2sParroquia%20Virgen%20de%20los%20Treinta%20y%20Tres!5e0!3m2!1ses-419!2suy!4v1697322257931!5m2!1ses-419!2suy'
    },
    {
        title:'Fiesta',
        li1:'Sábado 9 de Diciembre, 22:00 horas',
        li2:'Sede Social Treinta y Tres FC',
        li3:'Ramón Ortiz 1219',
        li4: 'Treinta y Tres',
        place: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3336.9200379832014!2d-54.38234359999999!3d-33.242398200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x950be9a41a0aadc9%3A0x22d29f9985f56ed2!2sSede%20Social%20Treinta%20y%20Tres%20FC!5e0!3m2!1ses-419!2suy!4v1697322125097!5m2!1ses-419!2suy'
    },

]

const EventSection = (props) => {
    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="events">
            <div className="container">
                <SectionTitle MainTitle={'FECHA Y LUGAR'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li>{event.li3}</li>
                                            <li>{event.li4}</li>
                                            <li>< LocationMap place={event.place}/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="wpo-event-wrap">
                    <div className="row">
                        <div className="col text-center">
                            <div className="wpo-event-text">
                                <ul>
                                    <li>Si viajas en ómnibus chequea los horarios de EGA, Nuñez, Expreso Minuano y Rutas Del Plata.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;