import React from 'react'
import SectionTitle from '../../components/SectionTitle'

const Footer = (props) =>{

  return(
    <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div className="contact-ft p-0">
                            <p>  </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;