import React, { useState } from 'react';
import { TabContent, TabPane} from 'reactstrap';
import sImg1 from '../../images/story/1.jpg'
import sIcon1 from '../../images/story/1.png'


import Shape from '../../images/story/shape.jpg'


const StorySection = (props) => {

    const [activeTab] = useState('1');

    const Story = [
        {
            Id: '1',
            sImg: sImg1,
            sIcon: sIcon1,
            Shape: Shape,
            date:'Cuenta: BROU | Caja Ahorro en $ | 001707854 - 00001',
            date2:'Nro. de cuenta anterior: 057 - 0244249',
            description:'Sin ti esto no sería igual, gracias por tu compañía en esta nueva etapa que comenzamos. El regalo es opcional, la asistencia obligatoria, pero si quieres tener un detalle con nosotros, suma kilómetros a nuestro viaje!'
        }
    ]

    return (

        <section className="wpo-story-section section-padding" id="story">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-12 col-12">
                        <div className="tab-area">
                            <TabContent activeTab={activeTab}>
                                {Story.map((story, Sitem) => (
                                    <TabPane tabId={story.Id} key={Sitem}>
                                        <div className="wpo-story-item">
                                            <div className="wpo-story-img">
                                                <img src={story.sImg} alt=""/>
                                            </div>
                                            <div className="wpo-story-content">
                                                <div className="wpo-story-content-inner">
                                                    <span><img src={story.sIcon} alt=""/></span>
                                                    <span>{story.description}</span>
                                                    <p>{story.date} <br></br> {story.date2}</p>
                                                    <div className="border-shape">
                                                        <img src={story.Shape} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default StorySection;