import React, { Component } from "react";
import { connect } from "react-redux";
import MobileMenu from "../MobileMenu";
import { removeFromCart } from "../../store/actions/action";
import { Link } from 'react-scroll'
import HeaderTopbar from "../HeaderTopbar";

class Header extends Component {
  state = {}

  render() {

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    }

    return (
      <header id="header" className={this.props.topbarBlock} >
        <HeaderTopbar />
        <div className={`wpo-site-header ${this.props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobile-menu">
                    <MobileMenu/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-1 col-1">
                  <div id="navbar" className="collapse navbar-collapse navigation-holder">
                    <button className="menu-close"><i className="ti-close"></i></button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li><Link onClick={ClickHandler} to="/home" spy={true} smooth={true} duration={500}>Inicio</Link></li>
                      <li><Link activeClass="active" to="gallery" spy={true} smooth={true} duration={500}>Fotos</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
