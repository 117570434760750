import React from 'react'
import iconVestimenta from '../../images/icon/icon_clothes.png'


const FunFact = (props) => {
    return(
      <section className={`wpo-fun-fact-section ${props.fClass}`}>
          <div className="container">
              <div className="row">
                  <div className="col">
                      <div className="wpo-fun-fact-grids clearfix">
                        <div class="grid">
                            <div className="info">
                                <img src={iconVestimenta} alt="icono-vestimenta"></img>
                                <h3>Vestimenta formal</h3>
                                <p>Evita el color blanco</p>
                            </div> 
                         </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    )
}

export default FunFact;