import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar';
import Hero from '../../components/hero';
import Scrollbar from '../../components/scrollbar';
import PortfolioSection2 from '../../components/PortfolioSection2';
import Footer from '../../components/footer';


const HomePage =() => {

    return(
        <Fragment>
            <Hero/>
            <PortfolioSection2/>
            <Scrollbar/>
        </Fragment>
    )
};

export default HomePage;