import React, { Component } from 'react'
import { Link} from 'react-scroll'
import './style.css';


export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow} = this.state;
        
        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close">
                         <div className="clox" onClick={this.menuHandler}><i className="ti-close"></i></div>
                    </div>
                    <ul className="responsivemenu">
                        <li><Link onClick={this.menuHandler} to="/home" spy={true} smooth={true} duration={500}>Inicio</Link></li>
                        <li><Link onClick={this.menuHandler} activeClass="active" to="events" spy={true} smooth={true} duration={500} >Fecha y lugar</Link></li>
                        <li><Link onClick={this.menuHandler} activeClass="active" to="RSVP" spy={true} smooth={true} duration={500} >Confirma asistencia</Link></li>
                        <li><Link onClick={this.menuHandler} activeClass="active" to="gallery" spy={true} smooth={true} duration={500}>Fotos</Link></li>
                    </ul>
                </div>
                <div className="mobail-menu showmenu" onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                            <span className="icon-bar first-angle"></span>
                            <span className="icon-bar middle-angle"></span>
                            <span className="icon-bar last-angle"></span>
                    </button>
                </div>
            </div>
        )
    }
}
